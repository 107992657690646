<template>
  <div>
    <div class="card">
      <b-overlay :show="showOverlay">
        <div class="card-body">
          <div class="row">
            <div class="col-md-7">
              <div class="data-shop-header" v-if="dto.id">{{ dto.name }}</div>
              <b-skeleton height="2.5rem" v-if="!dto.id"></b-skeleton>
              <div
                class="data-shop-author mt-5"
                style="display: flex; align-items: center"
              >
                <ul
                  class="u-star-rating-20"
                  :title="
                    $t('DATA_SHOP.RATING', {
                      rating: dto.rating,
                      votes: dto.votesCount,
                    })
                  "
                >
                  <li
                    class="u-current-rating"
                    :style="`width:${(dto.rating / 5) * 100}%`"
                  ></li>
                </ul>
                <div class="votes-count ml-3">
                  {{
                    dto.rating
                      ? $t("DATA_SHOP.VOTES", { votes: dto.votesCount })
                      : $t("DATA_SHOP.NO_VOTES")
                  }}
                </div>

                <b-button
                  class="ml-4"
                  size="sm"
                  variant="outline-secondary"
                  @click="openReviewModal"
                >
                  {{ $t("DATA_SHOP.VOTE") }}
                </b-button>
              </div>
              <div class="data-shop-card-text mt-4" v-if="dto.id">
                {{ dto.description }}
              </div>
              <b-skeleton height="10.5rem" v-if="!dto.id"></b-skeleton>
            </div>
            <div class="col-md-5">
              <b-skeleton-img v-if="!dto.id"></b-skeleton-img>
              <GmapMap
                v-if="dto.id"
                :center="{ lat: dto.latitude, lng: dto.longitude }"
                :zoom="5"
                map-type-id="terrain"
                style="width: 100%; height: 35vh"
                ref="mapRef"
              >
                <GmapCircle
                  :radius="dto.radius"
                  :center.sync="dto"
                  :options="{
                    strokeColor: '#2CC5BD',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: '#2CC5BD',
                    fillOpacity: 0.4,
                  }"
                ></GmapCircle>
              </GmapMap>
            </div>
          </div>
          <hr class="my-4" />
          <div class="stat-header">
            {{ $t("DATA_SHOP.DATA_COLLECTION_POINTS_COUNT") }}
          </div>
          <div class="row mt-3">
            <div class="col-md-auto">
              <div class="stat-value">{{ dto.devices.length }}</div>
            </div>
            <div class="col-md-auto">
              <button
                class="data-package-show-on-map"
                style="color: #1bc5bd"
                @click="showOnMap"
              >
                {{ $t("DATA_SHOP.SHOW_ON_MAP") }}
              </button>
            </div>
          </div>

          <template v-if="dto.beginDate && dto.endDate">
            <div class="stat-header mt-7">
              {{ $t("DATA_SHOP.DATA_COLLECTION_PERIOD") }}
            </div>
            <div class="stat-value mt-3">12.02.2019 — 20.01.2022</div>
          </template>

          <div class="stat-header mt-7">{{ $t("COMMON.AUTHOR") }}</div>

          <div class="stat-value mt-3">{{ dto.author }}</div>

          <hr class="my-4" />

          <div
            style="display: flex; align-items: center"
            v-if="dto.prices && !readOnly"
          >
            <div class="dp-price">
              {{ dto.prices[selectedCurrency.id] + selectedCurrency.letter }}
            </div>
            <button
              v-for="(cur, i) in currencies"
              type="button"
              :key="cur.id"
              @click="selectCurrency(cur)"
              :class="{
                btn: true,
                'btn-success': cur.id == selectedCurrency.id,
                'btn-secondary': cur.id != selectedCurrency.id,
                'ml-5': i === 0,
                'ml-3': i !== 0,
                'btn-rounded': true,
                'currency-inactive': cur.id != selectedCurrency.id,
              }"
            >
              {{ cur.letter }}
            </button>

            <button
              type="button"
              class="btn btn-success ml-10 btn-buy"
              @click="buy"
            >
              <i class="flaticon2-shopping-cart-1" /> {{ $t("pricing.buy") }}
            </button>
          </div>
        </div>
      </b-overlay>
    </div>
    <data-packages
      v-if="dto.id"
      class="mt-5"
      :title="$t('DATA_SHOP.OTHER_DATA_PACKAGES')"
      :excludeItem="dto.id"
    ></data-packages>
    <b-modal id="modal-order" size="lg" :centered="true">
      <div class="data-shop-card-text">
        {{ $t("MONITORING.SUCCESS_CREATED_ORDER") }}
      </div>
      <template #modal-footer>
        <div class="col-12 text-center">
          <b-button
            variant="primary"
            class="data-shop-card-text"
            @click="goToTeam"
            >{{ $t("COMMON.GO") }}</b-button
          >
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modal-review"
      :static="true"
      hide-header
      :centered="true"
      hide-footer
    >
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="$bvModal.hide('modal-review')"
      >
        ×
      </button>
      <h5 class="vote-header text-center">
        {{ $t("DATA_SHOP.VOTE_MODAL_HEADER") }}
      </h5>
      <div class="text-center mt-10">
        <div class="star-wrapper">
          <a
            href="#"
            class="fas fa-star s1"
            :style="dto.userRating >= 5 ? 'color: gold;' : null"
            @click.prevent="setRating(5)"
          ></a>
          <a
            href="#"
            class="fas fa-star s2"
            :style="dto.userRating >= 4 ? 'color: gold;' : null"
            @click.prevent="setRating(4)"
          ></a>
          <a
            href="#"
            class="fas fa-star s3"
            :style="dto.userRating >= 3 ? 'color: gold;' : null"
            @click.prevent="setRating(3)"
          ></a>
          <a
            href="#"
            class="fas fa-star s4"
            :style="dto.userRating >= 2 ? 'color: gold;' : null"
            @click.prevent="setRating(2)"
          ></a>
          <a
            href="#"
            class="fas fa-star s5"
            :style="dto.userRating >= 1 ? 'color: gold;' : null"
            @click.prevent="setRating(1)"
          ></a>
        </div>
      </div>
      <div class="text-center mt-10">
        <b-button
          variant="success"
          :disabled="!dto.userRating || dto.userRating === -1"
          @click="sendReview"
          >{{ $t("DATA_SHOP.VOTE") }}</b-button
        >
      </div>

      <b-overlay no-wrap :show="showVoteOverlay"></b-overlay>
    </b-modal>

    <b-modal
      id="modal-map"
      :static="true"
      hide-footer
      size="xl"
      body-class="no-padding"
      header-class="data-shop-header"
      :title="$t('DATA_SHOP.DATA_COLLECTION_POINTS_ON_MAP')"
    >
      <GmapMap
        v-if="dto.id"
        :center="{ lat: dto.latitude, lng: dto.longitude }"
        :zoom="5"
        map-type-id="terrain"
        style="width: 100%; height: 60vh"
        ref="mapRefModal"
      >
        <GmapMarker
          v-for="device in dto.devices"
          icon="/media/misc/map-point.svg"
          :position="device"
          :key="device.id"
        ></GmapMarker>
      </GmapMap>
    </b-modal>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DataPackages from "@/view/component/DataPackages.vue";
import ApiService from "@/core/services/api.service";
import Application from "@/application";
import { mapState } from "vuex";

export default {
  name: "buy-data-package",
  components: { DataPackages },
  data: function () {
    return {
      readOnly: false, // только менеджер команды может купить DataPackage
      dto: {
        id: null,
        name: "Data Package",
        description: "",
        devices: [],
      },

      showVoteOverlay: false,

      selectedCurrency: {
        letter: "₽",
        id: Application.constants.CURRENCIES.RUB,
      },

      currencies: [
        {
          letter: "₽",
          id: Application.constants.CURRENCIES.RUB,
        },
        {
          letter: "$",
          id: Application.constants.CURRENCIES.USD,
        },
        {
          letter: "€",
          id: Application.constants.CURRENCIES.EUR,
        },
      ],

      showOverlay: true,
    };
  },
  computed: {
    ...mapState({
      defaultTeam: (state) => state.team.defaultTeam,
    }),
  },

  beforeRouteUpdate: async function (to, from, next) {
    this.showOverlay = true;
    this.dto = {
      id: null,
      name: "Model",
      description: "",
      userRating: -1,
    };

    this.init(to.params.id);

    next();
  },

  created: async function () {
    this.init(this.$route.params.id);
  },

  methods: {
    init(id) {
      this.readOnly =
        this.defaultTeam.permissionId !=
        Application.constants.PERMISSIONS.MANAGE;
      ApiService.query("/api/data-package/public/" + id, {
        params: {
          teamId: this.defaultTeam.id,
        },
      })
        .then((result) => {
          this.dto = result.data;
          this.dto.lat = this.dto.latitude;
          this.dto.lng = this.dto.longitude;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.$t("MENU.DATA_SHOP"), route: "/shop" },
            { title: this.dto.name },
          ]);

          this.showOverlay = false;
        })
        .catch((e) => {
          this.$bvToast.toast(this.$t("COMMON.UNKNOWN_ERROR"), {
            title: this.$t("COMMON.ERROR"),
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },

    selectCurrency(cur) {
      this.selectedCurrency = cur;
    },

    buy: function () {
      this.showOverlay = true;

      let dto = {
        teamId: this.defaultTeam.id,
        productId: this.dto.products[0].id,
      };

      ApiService.post("/api/data-package/create-order", dto)
        .then(() => {
          this.$bvModal.show("modal-order");
        })
        .catch(() => {
          this.$bvToast.toast(this.$t("COMMON.UNKNOWN_ERROR"), {
            title: this.$t("COMMON.ERROR"),
            variant: "danger",
            autoHideDelay: 5000,
          });
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    goToTeam: function () {
      this.$bvModal.hide("modal-order");
      window.open("/team/" + this.defaultTeam.id, "_blank");
    },

    showOnMap: function () {
      this.$bvModal.show("modal-map");
    },

    openReviewModal: function () {
      this.$bvModal.show("modal-review");
    },

    setRating(rating) {
      this.dto.userRating = rating;
    },

    sendReview() {
      let dto = {
        referenceObjectId: this.dto.id,
        rating: this.dto.userRating,
      };
      this.showVoteOverlay = true;

      ApiService.post("/api/data-package/rate", dto)
        .then(async () => {
          this.$bvModal.hide("modal-review");
          this.$bvToast.toast(this.$t("DATA_SHOP.VOTING_SUCCESS"), {
            title: this.$t("DATA_SHOP.VOTING_THANK_YOU"),
            variant: "success",
            autoHideDelay: 5000,
          });

          let newDto = await ApiService.query(
            "/api/data-package/public/" + this.dto.id,
            {
              params: {
                teamId: this.defaultTeam.id,
              },
            }
          );

          this.dto.rating = newDto.data.rating;
          this.dto.votesCount = newDto.data.votesCount;
        })
        .catch((e) => {
          this.$bvToast.toast(this.$t("COMMON.UNKNOWN_ERROR"), {
            title: this.$t("COMMON.ERROR"),
            variant: "danger",
            autoHideDelay: 5000,
          });
        })
        .finally(() => {
          this.showVoteOverlay = false;
        });
    },
  },
};
</script>
