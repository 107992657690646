var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.slides !== null)?_c('b-card',[_c('div',{staticClass:"card-title",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"data-shop-header"},[_vm._v(" "+_vm._s(!_vm.title ? _vm.$t("MENU.DATA_PACKAGES") : _vm.title)+" ")]),(_vm.slides.length > 1)?_c('div',{staticStyle:{"margin-left":"auto","order":"2"}},[_c('button',{class:{
          btn: true,
          'btn-outline-pagination': _vm.page !== 0,
          'btn-outline-pagination-disabled': _vm.page === 0,
        },attrs:{"type":"button","disabled":_vm.page === 0},on:{"click":_vm.clickLeft}},[_vm._v(" < ")]),_c('button',{class:{
          'ml-2': true,
          btn: true,
          'btn-outline-pagination': _vm.page !== _vm.slides.length - 1,
          'btn-outline-pagination-disabled': _vm.page === _vm.slides.length - 1,
        },staticStyle:{"margin-left":"auto","order":"3"},attrs:{"disabled":_vm.page === _vm.slides.length - 1,"type":"button"},on:{"click":_vm.clickRight}},[_vm._v(" > ")])]):_vm._e()]),_c('b-overlay',{attrs:{"show":_vm.showOverlay}},[(_vm.slides && _vm.slides.length > 0)?_c('Carousel',{ref:"carousel",attrs:{"hide-arrows":true},on:{"page":_vm.pageChanged}},_vm._l((_vm.slides),function(slide){return _c('Slide',{key:slide.key},[_c('div',{staticClass:"row w-100",staticStyle:{"margin":"0 !important"}},_vm._l((slide.items),function(card){return _c('div',{key:card.id,staticClass:"col-lg-6 d-flex align-items-stretch"},[_c('div',{staticClass:"card border-secondary h-100"},[_c('img',{staticClass:"card-img-top",staticStyle:{"cursor":"pointer"},attrs:{"src":card.imageUrl === null
                    ? '/media/misc/map.png'
                    : card.imageUrl},on:{"click":function($event){$event.preventDefault();return _vm.showClicked(card.id)}}}),_c('div',{staticClass:"card-body"},[_c('a',{staticClass:"data-shop-inner-header link-blue-hover",attrs:{"href":'/shop/data-package/' + card.id},on:{"click":function($event){$event.preventDefault();return _vm.showClicked(card.id)}}},[_vm._v(" "+_vm._s(card.name)+" ")]),_c('div',{staticClass:"data-shop-card-text mt-2"},[_vm._v(" "+_vm._s(card.description)+" ")]),_c('div',{staticClass:"data-shop-author mt-5",staticStyle:{"display":"flex","align-items":"center"}},[_c('ul',{staticClass:"u-star-rating-20",attrs:{"title":_vm.$t('DATA_SHOP.RATING', {
                        rating: card.rating,
                        votes: card.votesCount,
                      })}},[_c('li',{staticClass:"u-current-rating",style:(`width:${(card.rating / 5) * 100}%`)})]),(card.author)?[_c('img',{staticClass:"ml-5",attrs:{"src":"/media/misc/profile.svg"}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(card.author))])]:_vm._e(),_c('b-button',{staticClass:"btn-open-pc",staticStyle:{"margin-left":"auto","order":"2"},attrs:{"variant":"success"},on:{"click":function($event){return _vm.showClicked(card.id)}}},[_vm._v(_vm._s(_vm.$t("DATA_SHOP.SHOW")))])],2),_c('b-button',{staticClass:"btn-open-mobile mt-2",attrs:{"variant":"success"},on:{"click":function($event){return _vm.showClicked(card.id)}}},[_vm._v(_vm._s(_vm.$t("DATA_SHOP.SHOW")))])],1)])])}),0)])}),1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }